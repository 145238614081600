import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/publishers",
    name: "publishers",
    component: () => import(/* webpackChunkName: "publishers" */ "../views/Publishers.vue")
  },
  {
    path: "/publishers/:publisherId",
    name: "publishers-id",
    component: () => import(/* webpackChunkName: "publishers" */ "../views/Publishers.vue"),
    props: true
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

// const indexRoutes = [
//   { path: "/publishers", name: "PublisherListPage", component: PublisherListPage },
//   { path: "/comic-series/:id/comics", name: "ComicSeriesComicsListPage", component: ComicSeriesComicsListPage },
//   { path: "/comic-series/:id", name: "ComicSeriesListPage", component: ComicSeriesListPage },
//   { path: "/", name: "LandingPage", component: LandingPage }
// ];
