import { store } from "@/store";
import { VuexModule, Module, Mutation } from "vuex-module-decorators";
import MenuItem from "@/models/MenuItem";

@Module({
  name: "menu",
  namespaced: true,
  stateFactory: true,
  dynamic: true,
  store
})
export default class MenuStore extends VuexModule {
  public topLevelMenu: Array<MenuItem> = [
    new MenuItem("Home", "/", "fa-home"),
    new MenuItem("Comics", "/publishers", "fa-home"),
    new MenuItem("About", "/about", "fa-home")
  ];

  public secondLevelMenuEnabled = false;
  public secondLevelMenuLoading = true;
  public secondLevelMenu: Array<MenuItem> = [];
  public secondLevelMenuFiltered: Array<MenuItem> = [];
  public secondLevelMenuTitle = "";

  @Mutation
  public setSecondLevelMenuEnabled(enabled: boolean): void {
    this.secondLevelMenuEnabled = enabled;
  }

  @Mutation
  public setSecondLevelMenuLoading(loading: boolean): void {
    this.secondLevelMenuLoading = loading;
  }

  @Mutation
  public setSecondLevelMenu(menu: Array<MenuItem>): void {
    this.secondLevelMenu = menu;
    this.secondLevelMenuFiltered = menu;
  }

  @Mutation
  public filterSecondLevelMenu(filter: string): void {
    if (filter == null) {
      filter = "";
    }
    this.secondLevelMenuFiltered = this.secondLevelMenu.filter(element => {
      return element.name.toLowerCase().search(filter.toLowerCase()) != -1;
    });
  }

  @Mutation
  public setSecondLevelMenuTitle(title: string): void {
    this.secondLevelMenuTitle = title;
  }
}
