import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/src/locale/de";
import "@fortawesome/fontawesome-free/css/all.css";
// import { preset } from "vue-cli-plugin-vuetify-preset-fortnightly/preset"; //https://github.com/vuetifyjs/vue-cli-plugins/tree/master/packages/%40vuetify/material-studies/templates/vue-cli-plugin-vuetify-preset-fortnightly

Vue.use(Vuetify);

export default new Vuetify({
  // preset,
  theme: {
    themes: {
      light: {
        primary: "#0066CC",
        secondary: "#003399",
        accent: "#CCCCFF",
        error: "#ff5722",
        warning: "#ff9800",
        info: "#03a9f4",
        success: "#4caf50"
      }
    }
  },
  lang: {
    locales: { de },
    current: "de"
  },
  icons: {
    iconfont: "fa"
  }
});
