






































import MenuStore from "@/store/modules/menu-store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

@Component
export default class SecondLevelMenu extends Vue {
  // @Prop({ required: true }) readonly test!: string;
  private menuStore: MenuStore = getModule(MenuStore);

  private secondLevelMenuFilter = "";

  @Watch("secondLevelMenuFilter")
  filterMenuItems(): void {
    this.menuStore.filterSecondLevelMenu(this.secondLevelMenuFilter);
  }
}
