


































import MenuStore from "@/store/modules/menu-store";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

@Component
export default class Header extends Vue {
  // @Prop({ required: true }) readonly test!: string;
  private menuStore: MenuStore = getModule(MenuStore);
}
