export default class MenuItem {
  name!: string;
  link!: string;
  icon!: string;

  public constructor(name: string, link: string, icon: string) {
    this.name = name;
    this.link = link;
    this.icon = icon;
  }
}
