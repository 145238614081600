



























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Welcome extends Vue {
  // @Prop({ required: true }) readonly test!: string;
}
