



































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import SecondLevelMenu from "@/components/SecondLevelMenu.vue";

@Component({
  components: {
    Header,
    SecondLevelMenu
  }
})
export default class App extends Vue {}
