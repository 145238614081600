










import { Component, Vue } from "vue-property-decorator";
import Welcome from "@/components/Welcome.vue";
// import MenuStore from "@/store/modules/menu-store";
// import { getModule } from "vuex-module-decorators";

@Component({
  components: {
    Welcome
  }
})
export default class Home extends Vue {
  // private menuStore: MenuStore = getModule(MenuStore);
  // public constructor() {
  //   super();
  //   this.menuStore.setSecondLevelMenuEnabled(false);
  // }
}
